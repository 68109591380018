/* eslint-disable no-param-reassign */

// ログイン中であるか
let isLoggedIn = $.cookie('is_logined') === 'true';

// ヘッダーヘルパ
const HeaderHelper = function () {
  // COOKIEの値セット
  function setCookie(data) {
    const expiresData = 7;
    const options = { expires: expiresData, path: '/' };
    $.cookie('is_logined', data.is_logined, options);
    $.cookie('is_owner', data.is_owner, options);
  }
  // COOKIEの値削除
  function removeCookie() {
    const expiresData = -1;
    const options = { expires: expiresData, path: '/' };
    $.cookie('is_logined', '', options);
    $.cookie('is_owner', '', options);
  }
  // ユーザ情報表示
  function dispUserInfo(data) {
    $('#topnavi_user_name').text(`${data.user_name}さん`);
    $('#topnavi_user_image').attr('src', data.image_path);
  }
  // 通知件数の表示
  function dispNoticeCount(unreadMessageCnt, unreadNotificationCnt) {
    const maxUnreadMessageCnt = 99;
    const maxUnreadNotificationCnt = 99;
    // 件数表示切替
    if (unreadMessageCnt > 0) {
      $('#unread_message_cnt').show();
    } else {
      $('#unread_message_cnt').hide();
    }
    if (unreadNotificationCnt > 0) {
      $('#unread_notification_cnt').show();
    } else {
      $('#unread_notification_cnt').hide();
    }
    // 画面表示の最大数
    if (unreadMessageCnt > maxUnreadMessageCnt) {
      unreadMessageCnt = `${maxUnreadMessageCnt}+`;
    }
    if (unreadNotificationCnt > maxUnreadNotificationCnt) {
      unreadNotificationCnt = `${maxUnreadNotificationCnt}+`;
    }
    $('#unread_message_cnt').text(unreadMessageCnt);
  }

  // 未公開プロジェクトリンク表示
  function dispReadyProject(data) {
    // 公開待ちプロジェクトがあるか
    if (data.has_ready_project) {
      $('.pjOpen').show();
    } else {
      $('.pjOpen').hide();
    }
  }
  function getUserInvestmentInfo(data) {
    if (!data.investment_return_title) {
      $('#footer_investment').css('display', 'flex');
      return;
    }
    if (data.investment_return_title.length > 40) {
      $('#invested-return-title').text(
        `${data.investment_return_title.substring(0, 39)}...`,
      );
    } else {
      $('#invested-return-title').text(data.investment_return_title);
    }
    $('#invested-return-date').text(
      `${data.investment_return_date}末までにお届け予定`,
    );
    $('#invested-return-price').text(data.total_return_price);
    if (data.investment_return_image) {
      $('#invested-return-image').attr('src', data.investment_return_image);
      $('#invested-return-image').attr('alt', data.investment_return_title);
    } else {
      $('#invested-return-image').addClass(
        'footer_user_investment_return_image--hidden',
      );
      $('#invested-return-title').addClass(
        'footer_user_investment_return_detail_no_image_title',
      );
      $('#invested-return-date').addClass(
        'footer_user_investment_return_no_image_shipping_date',
      );
    }
    $('#summary-view-count').text(data.summary.sum_view);
    $('#summary-conversion-count').text(data.summary.sum_conversion);
    $('#footer-twitter-share-url-button').attr(
      'data-token',
      data.twitter_share_token,
    );
    $('#footer-facebook-share-url-button').attr(
      'data-token',
      data.facebook_share_token,
    );
    $('#footer-line-share-url-button').attr(
      'data-token',
      data.line_share_token,
    );
    $(
      '#footer-user-share-url, #footer-user-share-activated-url-copy-button',
    ).attr('data-token', data.url_share_token);
    $('#footer-twitter-share-url-button').attr('href', data.twitter_share_link);
    $('#footer-facebook-share-url-button').attr(
      'href',
      data.facebook_share_link,
    );
    $('#footer-line-share-url-button').attr('href', data.line_share_link);
    $('#footer-user-share-url').attr('value', data.url_share_link);
    $('#footer-user-investment').css('display', 'block');
    if (data.has_multiple_investment) {
      $('#invested-return-etc').css('display', 'block');
    }
  }
  function showFavoriteToolTip(data) {
    const options = { path: '/', expires: 365 };
    let viewCount = $.cookie('view_count')
      ? parseInt($.cookie('view_count'), 10)
      : 0;
    if (viewCount === 0 || viewCount >= 2) {
      $('.tool-tip-wrapper').css('display', 'none');
      $.cookie('view_count', viewCount + 1, options);
      return;
    }
    viewCount += 1;
    $.cookie('view_count', viewCount, options);
    if (viewCount === 2 && !data.investment_return_title) {
      $('body').addClass('stop-scroll');
      const favoriteButton = $('.footer-investment__button_wrapper').eq(0);
      const toolTipPopup = $('.tool-tip-popup').eq(0);
      const buttonOffset = favoriteButton.offset();
      const buttonWidth = favoriteButton.width();
      const adjustLeft = -2;
      const adjustTop = 37;
      const buttonCenterX = buttonOffset.left + buttonWidth / 2 + adjustLeft;
      const buttonCenterY = $('#footer_investment').position().top + adjustTop;
      const popupX = buttonCenterX - toolTipPopup.offset().left;
      $('head').append(
        `<style>.tool-tip-popup:before { left: ${popupX}px }</style>`,
      );
      $('.tool-tip-wrapper').attr({
        style: `background:radial-gradient(33px 33px at ${buttonCenterX}px ${buttonCenterY}px, rgba(0,0,0,0) 32px , rgba(0,0,0,0.6) 33px)`,
      });
      $('.tool-tip-wrapper').animate(
        {
          opacity: 1,
        },
        500,
        () => {
          toolTipPopup.animate(
            {
              opacity: 1,
            },
            500,
          );
        },
      );
      $(document).on('click', '.tool-tip-popup-ok-button', () => {
        $('.tool-tip-wrapper').css('display', 'none');
        $('body').removeClass('stop-scroll');
      });
    }
  }

  // ログイン前後の表示非表示切替
  function dispLoginSwitch(loggedIn) {
    if (loggedIn) {
      $('.after_login').show();
      $('.before_login').hide();
      dispNoticeCount(
        $.cookie('unread_message_cnt'),
        $.cookie('unread_notification_cnt'),
      );
    } else {
      $('.after_login').hide();
      $('.before_login').show();
      $('#footer_investment').css('display', 'flex');
    }
  }

  function displayInvestmentsEcLink(data) {
    if (!data.investments_ec_count) {
      return;
    }
    $('#old-investments-sp-header-menu').show();
  }

  // ユーザのステータス取得
  function getUserStatus() {
    const url = '/api/userstatus/userstatus/';
    $.ajax({
      type: 'GET',
      url,
      dataType: 'json',
      data: { projectId: $('#project_id').val() },
      cache: false,
      success(data) {
        isLoggedIn = data.is_logined;
        setCookie(data);
        if (data.is_logined) {
          // ユーザ情報
          dispUserInfo(data);
          // 通知件数
          dispNoticeCount(
            data.unread_message_cnt,
            data.unread_notification_cnt,
          );
          // 未公開PJリンク
          dispReadyProject(data);
          // シェア支援情報の取得
          getUserInvestmentInfo(data);
          window.Geenie.Me.Favorite.headerToggle(data);
          displayInvestmentsEcLink(data);
          // brazeにユーザー識別子を送信
          window.braze.changeUser(data.userid);
        } else {
          // クッキ削除
          removeCookie();
        }
        // GTM用データをwindow.dataLayerに書き込み
        window.Geenie.GtmDataLayer.push_userid(data.userid);
        // 表示切替
        dispLoginSwitch(isLoggedIn);

        if (
          String($('#is-native').val()).toLowerCase() === 'true' &&
          window.location.pathname.match(/^\/project\/[0-9A-Za-z\-_]+\/?$/) &&
          Boolean($('#is-coming-soon').val()) === false
        ) {
          $(window).on('load', () => {
            setTimeout(() => {
              showFavoriteToolTip(data);
            }, 500);
          });
        }
      },
    });
  }

  function logoutWithBraze() {
    const logoutLink = $('a[href^="/logout"]');
    logoutLink.on('click', e => {
      e.preventDefault();

      if (window.braze) {
        // Brazeのログアウト処理
        window.braze.wipeData();
      }

      window.location.href = logoutLink.attr('href');
    });
  }

  // 初期処理
  this.init = function () {
    // ログイン直後は見ない（違和感ないように）
    if ($.cookie('is_logined')) {
      // 表示切替（違和感ないように一旦Cookie情報で見る）
      dispLoginSwitch(isLoggedIn);
    }
    // ユーザステータスによる表示の切り替え
    getUserStatus();
    logoutWithBraze();
  };
};

$(() => {
  const helper = new HeaderHelper();
  helper.init();
});

// メニュー表示・非表示
$('.hamburger').on('click', () => {
  if (isLoggedIn) {
    $('#sidrBase').hide();
    $('#sidrBase02').toggle();
    $('.gestSpace').removeClass('hidden');
  } else {
    $('#sidrBase02').hide();
    $('#sidrBase').toggle();
  }
  $('.triggerSuggest').empty();
  $('.triggerSuggest').css('display', 'none');
  $('div.search-criteria-histories-wrapper').css('display', 'none');
  $('.triggerKeyword').val('');
  $('.triggerSidebarNav').removeClass('hidden');
  let clickElementId = '';
  // メニュー／メニュー以外の表示切替
  if ($('#sidrBase').is(':visible') || $('#sidrBase02').is(':visible')) {
    // メール認証
    $('.alertMail_wrap').hide();
    // wrapperを消す
    $('#wrapper').css('visibility', 'hidden');
    // slick js の画像を消す
    $('.cd-box-img--thumb').hide();
    // footerを消す
    $('#footer').hide();
    // ハンバーガーボタン切り替え
    $('.hamburger-icon-box').removeClass('hamburger-icon-show');
    $('.hamburger-icon-box').addClass('hamburger-icon-disnon');
    $('.hamburger-close-box').removeClass('hamburger-icon-disnon');
    $('.hamburger-close-box').addClass('hamburger-icon-show');
    // お知らせメニュー展開中にbodyのスクロールをしないようにするのを解除
    $('body').addClass('no-scrollable');
    clickElementId = 'header_menu_open';
  } else {
    // メール認証
    $('.alertMail_wrap').show();
    // wrapperを表示
    $('#wrapper').css('visibility', 'visible');
    // slick js の画像を表示
    $('.cd-box-img--thumb').show();
    // footerを表示
    $('#footer').show();
    // ハンバーガーボタン切り替え
    $('.hamburger-icon-box').addClass('hamburger-icon-show');
    $('.hamburger-icon-box').removeClass('hamburger-icon-disnon');
    $('.hamburger-close-box').addClass('hamburger-icon-disnon');
    $('.hamburger-close-box').removeClass('hamburger-icon-show');
    // お知らせメニュー展開中にbodyのスクロールをしないようにするのを解除
    $('body').removeClass('no-scrollable');
    clickElementId = 'header_menu_close';
  }
  // スクロールしてTOPに移動する これで位置連れを解消
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;

  if (clickElementId) {
    window.Geenie?.ClickTracking?.sendClickEvent?.(clickElementId);
  }

  return false;
});

// サブメニュー
$('.js-open').on('click', function () {
  let action = '';
  if ($(this).next('.js-menu-box-child-disnon').css('display') === 'block') {
    $(this).children('i').removeClass('downarrow');
    $(this).children('i').addClass('rightarrow');
    action = 'close';
  } else {
    $(this).children('i').removeClass('rightarrow');
    $(this).children('i').addClass('downarrow');
    action = 'open';
  }
  if ($(this).next('.js-menu-box-child-disnon').size() > 0) {
    $(this).next('.js-menu-box-child-disnon').slideToggle();
  }

  const clickElementIdPrefix = $(this).data('click-element-element-id');
  if (clickElementIdPrefix && action) {
    window.Geenie?.ClickTracking?.sendClickEvent?.(`${clickElementIdPrefix}_${action}`);
  }

  return false;
});
